export const SCOREBOARD_ACTIONS = {
  SCOREBOARD_LOADING: "SCOREBOARD_LOADING",
  SCOREBOARD_LOADED: "SCOREBOARD_LOADED",
  SCOREBOARD_ERROR: "SCOREBOARD_ERROR",
};

export const WINNERS_ACTIONS = {
  DAILYWINNERS_LOADING: "DAILYWINNERS_LOADING",
  DAILYWINNERS_LOADED: "DAILYWINNERS_LOADED",
  DAILYWINNERS_ERROR: "DAILYWINNERS_ERROR",
  MONTHLYWINNERS_LOADING: "MONTHLYWINNERS_LOADING",
  MONTHLYWINNERS_LOADED: "MONTHLYWINNERS_LOADED",
  MONTHLYWINNERS_ERROR: "MONTHLYWINNERS_ERROR",
};
