import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../constants/fonts/Poppins-Bold.ttf";
export default function SuccessPage() {
  const queryParams = new URLSearchParams(window.location.search);
  const lng = queryParams.get("lng");
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (lng?.includes("hu")) {
      i18n.changeLanguage("hu");
    } else if (lng?.includes("en")) {
      i18n.changeLanguage("en");
    } else if (lng?.includes("pl")) {
      i18n.changeLanguage("pl");
    } else if (lng?.includes("fr")) {
      i18n.changeLanguage("fr");
    }else if (lng?.includes("bg")) {
      i18n.changeLanguage("bg");
    }
  }, [i18n, lng]);

  return (
    <div
      style={{
        textAlign: "center",
        fontFamily: "PoppinsBold",
        color: "#182d71",
        fontSize: "2.7vw",
        padding:"1em"
      }}
    >
      {t("success-message")}
    </div>
  );
}
