import { Row, Table } from "antd";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDailyWinners, getMonthlyWinners } from "../../actions/winners";
import "../../constants/fonts/Poppins-Bold.ttf";

export default function Winners() {
  const dispatch = useDispatch();
  const dailyWinners = useSelector(
    (state) => state.winnersReducer.dailyWinners
  );
  const dailyWinnersStatus = useSelector(
    (state) => state.winnersReducer.dailyWinnersStatus
  );
  const monthlyWinners = useSelector(
    (state) => state.winnersReducer.monthlyWinners
  );
  const monthlyWinnersStatus = useSelector(
    (state) => state.winnersReducer.monthlyWinnersStatus
  );
  const effectRan = useRef(false);
  const [dailyWinnerLoaded, setDailyWinnerLoaded] = useState(() => false);
  const [monthlyWinnerLoaded, setMonthlyWinnerLoaded] = useState(() => false);

  useEffect(() => {
    const fetch = async () => {
      if (effectRan.current === false) {
        await dispatch(getDailyWinners());
        await dispatch(getMonthlyWinners());
        if (dailyWinnersStatus) {
          setDailyWinnerLoaded(true);
        }
        if (monthlyWinnersStatus) {
          setMonthlyWinnerLoaded(true);
        }
      }
    };
    fetch();
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, dailyWinnersStatus, monthlyWinnersStatus]);

  const columns = [
    {
      key: "name",
      render: (text, record) => {
        return (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div
              style={{
                fontFamily: "PoppinsBold",
                color: "#182d71",
                fontSize: "2em",
              }}
            >
              {record.name}
            </div>
          </div>
        );
      },
      width: "40%",
    },
    {
      key: "paint",
      render: (text, record) => {
        return (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div
              style={{
                fontFamily: "PoppinsBold",
                color: "#182d71",
                fontSize: "1.5em",
                marginLeft: "1em",
              }}
            >
              {record.score} pont
            </div>
          </div>
        );
      },
      width: "30%",
    },
    {
      key: "date",
      render: (text, record) => {
        return (
          <div
            style={{
              fontFamily: "PoppinsBold",
              color: "#182d71",
              fontSize: "1.5em",
            }}
          >
            {new Date(
              new Date(record.created).setDate(
                new Date(record.created).getDate() - 1
              )
            ).toLocaleDateString()}
          </div>
        );
      },
      width: "60%",
    },
  ];
  return (
    <div>
      <Row>
        <div
          style={{
            fontFamily: "PoppinsBold",
            color: "#182d71",
            fontSize: "2.5em",
            marginLeft: "21.5vw",
          }}
        >
          Napi nyertesek
        </div>
        <div
          style={{
            fontFamily: "PoppinsBold",
            color: "#182d71",
            fontSize: "2.5em",
            marginLeft: "31vw",
          }}
        >
          Havi nyertesek
        </div>
      </Row>
      <Row>
        <Table
          columns={columns}
          rowKey="id"
          dataSource={dailyWinners}
          locale={{ emptyText: " " }}
          loading={!dailyWinnerLoaded && !dailyWinners}
          showHeader={false}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            style: { marginRight: "50%" },
          }}
          style={{ width: "40%", marginLeft: "10%", marginRight: "auto" }}
        />
        <Table
          columns={columns}
          rowKey="id"
          dataSource={monthlyWinners}
          locale={{ emptyText: " " }}
          loading={!monthlyWinnerLoaded && !monthlyWinners}
          showHeader={false}
          pagination={{
            pageSize: 10,
            showSizeChanger: false,
            style: { marginRight: "50%" },
          }}
          style={{ width: "40% ", marginLeft: "auto", marginRight: "auto" }}
        />
      </Row>
    </div>
  );
}
