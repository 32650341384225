import axios from "axios";
import { WINNERS_ACTIONS } from "../constants/action-types";

export const getDailyWinners = () => {
  return async (dispatch) => {
    dispatch({ type: WINNERS_ACTIONS.DAILYWINNERS_LOADING });
    await axios
      .get("/1.0.0/daily-winner", {})
      .then((response) => {
        dispatch({
          type: WINNERS_ACTIONS.DAILYWINNERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: WINNERS_ACTIONS.DAILYWINNERS_ERROR,
          payload: err.response.data,
        });
      });
  };
};

export const getMonthlyWinners = () => {
  return async (dispatch) => {
    dispatch({ type: WINNERS_ACTIONS.MONTHLYWINNERS_LOADING });
    await axios
      .get("/1.0.0/monthly-winner", {})
      .then((response) => {
        dispatch({
          type: WINNERS_ACTIONS.MONTHLYWINNERS_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: WINNERS_ACTIONS.MONTHLYWINNERS_ERROR,
          payload: err.response.data,
        });
      });
  };
};
