import React from "react";
import ConnectedForgotPasswordConfirm from "./components/Login/forgotpasswordconfirm";
import "./reset.css";
import "./App.css";
import "./App.css";
import axios from "axios";
import { Suspense } from "react";
import { Spin } from "antd";
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Switch } from "react-router-dom";
import Scoreboard from "./components/Scoreboard/socreboard";
import Winners from "./components/Winners/winners";
import SuccessPage from "./components/Login/successpage";

function App() {
  axios.defaults.baseURL = "https://flavon-api.sites.appentum.pro/api";
  // axios.defaults.baseURL = process.env.REACT_APP_BASE_URL + "/api";
  return (
    <Suspense fallback={<Spin spinning={true} />}>
      <div style={{ height: "100%" }}>
        <Helmet>
          <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/" component={Winners} />
            <Route exact path="/scoreboard" component={Scoreboard} />
            <Route
              exact
              path="/forgot-password-confirm"
              component={ConnectedForgotPasswordConfirm}
            />
            <Route
              exact
              path="/success-page"
              component={SuccessPage}
            />
          </Switch>
        </Router>
      </div>
    </Suspense>
  );
}

export default App;
