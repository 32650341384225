import { Table } from "antd";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScoreboard } from "../../actions/scoreboard";
import "../../constants/fonts/Poppins-Bold.ttf";

export default function Socreboard() {
  const propScoreboard = useSelector(
    (state) => state.scoreboardReducer.scoreboard
  );
  const propStatus = useSelector((state) => state.scoreboardReducer.scoreboard);
  const dispatch = useDispatch();
  const effectRan = useRef(false);
  const [loading, setLoading] = useState(() => false);
  const [sortedData, setSortedData] = useState(() => []);

  useEffect(() => {
    const fetch = async () => {
      if (effectRan.current === false) {
        dispatch(getScoreboard());
      }
      if (propStatus) {
        setLoading(true);
        setSortedData([]);
        for (let i = 0; i < 7; i++) {
          const element = {
            id: propScoreboard[i]?.id,
            place: i + 1,
            name: propScoreboard[i]?.name,
            score: propScoreboard[i]?.score,
          };
          setSortedData((pre) => [...pre, element]);
        }
      }
    };
    fetch();
    return () => {
      effectRan.current = true;
    };
  }, [dispatch, propStatus, propScoreboard]);

  const columns = [
    {
      key: "place",
      dataIndex: "place",
      render: (text, record) => {
        return (
          <div
            style={{
              fontSize: "7vw",
              // marginTop: "auto",
              // marginBottom: "auto",
              // marginRight: "auto",
              // marginLeft: "auto",
              fontFamily: "PoppinsBold",
              color: "#182d71",
            }}
          >
            {record.place}.
          </div>
        );
      },
      align: "center",
    },
    // {},
    {},
    {
      key: "name",
      render: (text, record) => {
        return (
          <div style={{ marginTop: "auto", marginBottom: "auto" }}>
            <div
              style={{
                fontSize: "11vw",
                fontFamily: "PoppinsBold",
                color: "#182d71",
              }}
            >
              {record.score} pont
            </div>
            <div
              style={{
                fontFamily: "PoppinsBold",
                color: "#182d71",
                fontSize: "5vw",
              }}
            >
              {record.name}
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      rowKey="id"
      dataSource={sortedData}
      locale="Nem található tartalom!"
      loading={!loading && !sortedData}
      showHeader={false}
      pagination={false}
      style={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}
    />
  );
}
