import axios from "axios";
import { SCOREBOARD_ACTIONS } from "../constants/action-types";

export const getScoreboard = () => {
  return async (dispatch) => {
    dispatch({ type: SCOREBOARD_ACTIONS.SCOREBOARD_LOADING });
    await axios
      .get("/1.0.0/users", {})
      .then((response) => {
        dispatch({
          type: SCOREBOARD_ACTIONS.SCOREBOARD_LOADED,
          payload: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: SCOREBOARD_ACTIONS.SCOREBOARD_ERROR,
          payload: err.response.data,
        });
      });
  };
};
