import { Button, Form, Input, message } from "antd";
import Axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import pic from "../../constants/logo.png";
import useWindowDimensions from "./sizecheck";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 10,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};
function ConnectedForgotPasswordConfirm() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(() => true);
  const [success, setSuccess] = useState(() => false);
  const [user, setUser] = useState(() => {});
  const [password, setPassword] = useState(() => "");
  const functionRan = useRef(false);
  const history = useHistory();
  const [buttonDisabler, setButtonDisabler] = useState(() => true);
  const { t, i18n } = useTranslation();
  const { height, width } = useWindowDimensions();
  const [searchTerm, setSearchTerm] = useState(() => "");

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");
  const lng = queryParams.get("lng");
  useEffect(() => {
    if (lng?.includes("hu")) {
      i18n.changeLanguage("hu");
    } else if (lng?.includes("en")) {
      i18n.changeLanguage("en");
    } else if (lng?.includes("pl")) {
      i18n.changeLanguage("pl");
    } else if (lng?.includes("fr")) {
      i18n.changeLanguage("fr");
    } else if (lng?.includes("bg")) {
      i18n.changeLanguage("bg");
    }
  }, [i18n, lng]);

  useEffect(() => {
    const fetch = async () => {
      if (functionRan.current === false) {
        await Axios.get("/1.0.1/users/forgot-password-confirm", {
          params: { token },
        })
          .then((response) => {
            setUser(response.data);
            setLoading(false);
            setSuccess(true);
          })
          .catch((err) => {
            setLoading(false);
            setSuccess(false);
          });
      }
    };
    fetch();
    return () => {
      functionRan.current = true;
    };
  }, [token]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTerm !== "") {
        setPassword(searchTerm);
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const modifyUserPassword = async () => {
    setButtonDisabler(true);
    const userParams = {
      id: user.id,
      password: password,
      language: lng?.toString(),
    };
    Axios.put("/1.0.1/users/modify-password", userParams, {
      params: { token },
    })
      .then((response) => {
        history.push(`/success-page?lng=${lng}`);
      })
      .catch((err) => {
        message.error(t("token-error"), 3);
      });
  };

  return (
    <div>
      {loading ? (
        t("waiting")
      ) : !loading && success ? (
        <div>
          <div style={{ textAlign: "center" }}>
            <img
              src={pic}
              alt="pic"
              style={
                width >= 576
                  ? {
                      maxWidth: "30vw",
                      display: "inline-block",
                      paddingTop: "2em",
                    }
                  : {
                      maxWidth: "40vw",
                      display: "inline-block",
                      paddingTop: "2em",
                      marginLeft: "2vw",
                    }
              }
            />
          </div>
          <Form
            form={form}
            {...formItemLayout}
            name="register"
            onFinish={modifyUserPassword}
            scrollToFirstError
            style={
              width >= 576
                ? { paddingTop: "2em", marginRight: "2em" }
                : { paddingTop: "2em", marginLeft: "3em", marginRight: "3em" }
            }
          >
            <Form.Item name="name" label={t("name-label")}>
              <Input
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="name"
                placeholder={user.name}
                disabled={true}
              />
            </Form.Item>

            <Form.Item name="email" label={t("email-label")}>
              <Input
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="email"
                placeholder={user.email}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label={t("password-label")}
              rules={[
                {
                  required: true,
                  message: t("fill-required-error-message"),
                },
                {
                  pattern: /^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d))/,
                  message: t("password-requires-error-message"),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                autoComplete="new-password"
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="password"
                value={password}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              name="passwordcheck"
              label={t("password-check-label")}
              dependencies={["password"]}
              rules={[
                {
                  required: password.length > 0,
                  message: t("fill-required-error-message"),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (getFieldValue("password") === value) {
                      if (
                        !/^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d))/.test(password)
                      ) {
                        setButtonDisabler(true);
                        return Promise.reject();
                      }
                      setButtonDisabler(false);
                      return Promise.resolve();
                    }
                    setButtonDisabler(true);
                    return Promise.reject(t("validation-error"));
                  },
                }),
              ]}
            >
              <Input.Password
                autoComplete="new-password"
                style={width >= 576 ? { maxWidth: "20vw" } : { width: "100%" }}
                name="passwordcheck"
                type="password"
              />
            </Form.Item>
          </Form>
          <div style={{ textAlign: "center" }}>
            <Button
              disabled={buttonDisabler}
              type="primary"
              htmlType="submit"
              onClick={() => modifyUserPassword()}
              style={{ display: "inline-block" }}
            >
              {t("save-button-label")}
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            fontFamily: "PoppinsBold",
            color: "#182d71",
            fontSize: "2.5em",
            marginLeft: "20%",
            marginRight: "auto",
          }}
        >
          {t("token-error")}
        </div>
      )}
    </div>
  );
}

export default ConnectedForgotPasswordConfirm;
