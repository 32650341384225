import { WINNERS_ACTIONS } from "../constants/action-types";

const defaultState = {
  dailyWinners: [],
  dailyWinnersStatus: false,
  dailyWinnersMessage: "",
  monthlyWinners: [],
  monthlyWinnersStatus: false,
  monthlyWinnersMessage: "",
};

const winnersReducer = (state = defaultState, action) => {
  switch (action.type) {
    case WINNERS_ACTIONS.DAILYWINNERS_LOADING:
      return Object.assign({}, state, {
        dailyWinners: [],
        dailyWinnersStatus: false,
        dailyWinnersMessage: "",
      });
    case WINNERS_ACTIONS.DAILYWINNERS_LOADED:
      return Object.assign({}, state, {
        dailyWinners: action.payload.winners,
        dailyWinnersStatus: true,
        dailyWinnersMessage: "",
      });
    case WINNERS_ACTIONS.DAILYWINNERS_ERROR:
      return Object.assign({}, state, {
        dailyWinners: [],
        dailyWinnersStatus: false,
        dailyWinnersMessage: action.payload,
      });
    case WINNERS_ACTIONS.MONTHLYWINNERS_LOADING:
      return Object.assign({}, state, {
        monthlyWinners: [],
        monthlyWinnersStatus: false,
        monthlyWinnersMessage: "",
      });
    case WINNERS_ACTIONS.MONTHLYWINNERS_LOADED:
      return Object.assign({}, state, {
        monthlyWinners: action.payload.winners,
        monthlyWinnersStatus: true,
        monthlyWinnersMessage: "",
      });
    case WINNERS_ACTIONS.MONTHLYWINNERS_ERROR:
      return Object.assign({}, state, {
        monthlyWinners: [],
        monthlyWinnersStatus: false,
        monthlyWinnersMessage: action.payload,
      });
    default:
      return state;
  }
};

export default winnersReducer;
