import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/session";
import scoreboardReducer from "../reducers/scoreboard";
import winnersReducer from "../reducers/winners";

const persistConfig = {
  key: "root",
  storage: storage,
  blacklist: ["scoreboardReducer"],
};

const appReducer = combineReducers({
  scoreboardReducer,
  winnersReducer,
});

const persistedReducer = persistReducer(persistConfig, appReducer);

export const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export const persistor = persistStore(store);
