import { SCOREBOARD_ACTIONS } from "../constants/action-types";

const defaultState = {
  scoreboard: [],
  status: false,
  message: "",
};

const scoreboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case SCOREBOARD_ACTIONS.SCOREBOARD_LOADING:
      return Object.assign({}, state, {
        scoreboard: [],
        status: false,
        message: "",
      });
    case SCOREBOARD_ACTIONS.SCOREBOARD_LOADED:
      return Object.assign({}, state, {
        scoreboard: action.payload.users,
        status: true,
        message: "",
      });
    case SCOREBOARD_ACTIONS.SCOREBOARD_ERROR:
      return Object.assign({}, state, {
        scoreboard: [],
        status: false,
        message: action.payload,
      });
    default:
      return state;
  }
};

export default scoreboardReducer;
